<template>
  <div class="users">
    <Breadcrumb name1="用户管理" name2="用户列表" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <!-- <el-col :span="7"> -->
          <!-- 搜索与添加区域 -->
          <!-- <el-input placeholder="请输入内容" clearable v-model="queryInfo.query" @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="4">
          <!-- 添加用户区域 -->
          <el-button type="primary" @click="addDialogVisible = true">用户添加</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="使用中" name="first">
          <el-table :data="deepCopyUserList" stripe style="width: 100%">
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column prop="name" label="姓名" width="120px"></el-table-column>
            <el-table-column prop="phone" label="电话"></el-table-column>
            <el-table-column prop="extra.login_num" label="登陆次数" sortable width="120px" align="center"></el-table-column>
            <el-table-column label="所在部门" width="140px" prop="department_id" sortable>
              <template v-slot="scope">
                {{trDepart(scope.row.department_id)}}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="申请时间" width="200px" sortable></el-table-column>
            <el-table-column label="接收通知" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.receive_status" :active-value='1' :inactive-value='2' @change="userMessageStatuChanged(scope.row)"> </el-switch>
              </template>
            </el-table-column>
            <!-- <el-table-column label="审核" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.status" @change="userStatuChanged(scope.row)"> </el-switch>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="120px" align="right">
              <template v-slot="scope">
                <!-- 修改按钮 -->
                <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button> -->
                <!-- 删除按钮 -->
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)">删除</el-button>
                <!-- 分配角色按钮 -->
                <!-- <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
                  <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRoles(scope.row)"></el-button>
                </el-tooltip> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            @size-change="handleSizeChange"
            :page-size="queryInfo.pageSize"
            :page-sizes="[20,30,40,50]"
            layout="total, prev, pager, next, jumper"
            :total="userData.total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="审核中" name="second">
          <el-table :data="auditUserList" stripe style="width: 100%">
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column prop="name" label="姓名" width="120px"></el-table-column>
            <el-table-column prop="phone" label="电话"></el-table-column>
            <el-table-column prop="department_id" label="部门ID" sortable width="100px"></el-table-column>
            <el-table-column label="单位名称" width="140px">
              <template v-slot="scope">
                {{trDepart(scope.row.department_id)}}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="申请时间" width="200px" sortable></el-table-column>
            <el-table-column label="操作" width="120px">
              <template v-slot="scope">
                <!-- 修改按钮 -->
                <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button> -->
                <!-- 删除按钮 -->
                <el-button type="success" icon="el-icon-check" size="mini" @click="auditUserById(scope.row.id)"></el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)"></el-button>
                <!-- 分配角色按钮 -->
                <!-- <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
                  <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRoles(scope.row)"></el-button>
                </el-tooltip> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            @size-change="handleSizeChange"
            :page-size="queryInfo.pageSize"
            :page-sizes="[20,30,40,50]"
            layout="total, prev, pager, next, jumper"
            :total="userData.total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="已删除" name="third">
          <el-table :data="delUserList" stripe style="width: 100%">
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column prop="name" label="姓名" width="120px"></el-table-column>
            <el-table-column prop="phone" label="电话"></el-table-column>
            <el-table-column prop="department_id" label="部门ID" sortable width="100px"></el-table-column>
            <el-table-column label="单位名称" width="140px">
              <template v-slot="scope">
                {{trDepart(scope.row.department_id)}}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="申请时间" width="200px" sortable></el-table-column>
            <!-- <el-table-column label="接收通知" width="80px">
              <template v-slot="scope">
                {{scope.row.receive_status}}
                <el-switch v-model="scope.row.receive_status" active-value=“1” inactive-value=2 @change="userMessageStatuChanged(scope.row)"> </el-switch>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="审核" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.status" @change="userStatuChanged(scope.row)"> </el-switch>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="120px">
              <template v-slot="scope">
                <!-- 修改按钮 -->
                <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button> -->
                <!-- 删除按钮 -->
                <el-button type="primary" icon="el-icon-refresh-left" size="mini" @click="reactivateUserById(scope.row.id)">恢复</el-button>
                <!-- 分配角色按钮 -->
                <!-- <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
                  <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRoles(scope.row)"></el-button>
                </el-tooltip> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            @size-change="handleSizeChange"
            :page-size="queryInfo.pageSize"
            :page-sizes="[20,30,40,50]"
            layout="total, prev, pager, next, jumper"
            :total="userData.total"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="500px" @close="addDislogClosed">
      <!-- 内容主题区域 -->
      <el-form label-width="70px" ref="addFormRef" :model="addForm" :rules="addFormRules">
        <el-form-item label="用户姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所在单位" prop="department_id">
          <!-- <el-input v-model="addForm.department_id"></el-input> -->
          <el-select v-model="addForm.department_id" clearable placeholder="请选择">
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="移动电话" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-input v-model="addForm.type"></el-input>1管理员 2办事人员
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户信息对话框 -->
    <el-dialog title="修改用户" @close="aditClosed" :visible.sync="editDialogVisble" width="50%">
      <el-form :model="editForm" :rules="addFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="用户名">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisble = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配角色 -->
    <el-dialog title="分配角色" :visible.sync="setRolesDialogVisible" @close="setRolesDialogClosed" width="50%">
      <div>
        <p>当前的用户 : {{ userInfo.username }}</p>
        <p>当前的角色 : {{ userInfo.role_name }}</p>
        <p>
          分配新角色:
          <el-select v-model="selectRoleId" placeholder="请选择">
            <el-option v-for="item in rolesList" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRolesDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRolesInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userAddFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Users',
  mixins: [userAddFormRulesMixin],
  data() {
    return {
      search: '',
      activeName: 'first',
      // 获取用户列表的参数对象
      queryInfo: {
        page: 1,
        // 当前每次显示多少条数据
        pageSize: 500
      },
      // 存放用户的数据和数量
      delUserList: [],
      auditUserList: [],
      deepCopyUserList: [],
      userData: {
        userList: [
          // {
          //   // "id": 1, // 用户id
          //   // "name": "yangsl", // 用户名
          //   // "created_at": null, //生成时间
          //   // "updated_at": "2022-09-27 14:22:34", // 更新时间
          //   // "department_id": 1, //单位id
          //   // "openid": "ozPi54oAYP6AMK-xpo8xZx31ti9Y", // 微信openid
          //   // "phone": "13023946608", // 电话号
          //   // "nick_name": "", // 微信昵称
          //   // "avatar_url": "", // 微信头像
          //   // "username": "", // 用户名
          //   // "type": 1, // 用户类型：1:管理员2:普通用户
          //   // "status": 9, // 用户状态：1:待审核2:审核通过9:停用
          //   // "is_default": 2, // 是否是默认用户1:是2否
          //   // "receive_status": 2 // 是否接收通知：是2否
          // }
        ],
        total: 0
      },
      // 控制用户对话框的显示和隐藏
      addDialogVisible: false,
      // 添加用户数据的对象
      addForm: {
        name: '',
        phone: '1',
        department_id: 1,
        type: 2
      },
      // 修改用户消息对话框显示和隐藏
      editDialogVisble: false,
      // 控制分配角色对话框的显示和隐藏
      setRolesDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfo: {},
      // 分配角色列表
      rolesList: [],
      // 保存已经选中的角色id值
      selectRoleId: '',
      // 查询用户的对象
      editForm: {},
      departmentList: [],
      departmentsQueryInfo: {
        page: 1,
        pageSize: 50
      }
    }
  },
  components: {
    Breadcrumb
  },
  created() {
    this.getDepartmentsList()
    this.getUserList()
  },
  filters: {
    isActiveFitlter: (value) => {
      return value === 1
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    trDepart(val) {
      const userData = this.departmentList.filter(item => { return item.id === val * 1 })
      const { name } = JSON.parse(JSON.stringify(userData))[0]
      return name
    },
    // 关联部门数据
    async getDepartmentsList() {
      const { data: res } = await this.$http.post('department/list', this.departmentsQueryInfo)
      // console.log(res.data)
      if (res.code !== 200) {
        this.$message.error('获取部门列表失败!')
      }
      this.departmentList = res.data
    },
    async getUserList() {
      const res = await this.$http.post('user/list', this.queryInfo)
      const trRes = JSON.parse(JSON.stringify(res.data.data))
      // console.log(trRes)
      // console.log(JSON.parse(JSON.stringify(res.data.data)))
      if (res.data.code !== 200) {
        this.$message.error('获取用户列表失败!')
      }
      this.$message.success('获取用户列表成功!')
      this.userData.userList = JSON.parse(JSON.stringify(res.data.data)) // trRes.filter(item => item.status === 2)
      this.deepCopyUserList = [...JSON.parse(JSON.stringify(res.data.data))]
      this.userData.total = res.data.options.total
      this.delUserList = this.deepCopyUserList.filter(item => item.status === 9)
      this.auditUserList = this.deepCopyUserList.filter(item => item.status === 1)
      console.log(this.deepCopyUserList)
    },
    // 监听 pagesize 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log(this.queryInfo.pageSize)
      this.queryInfo.page = newPage
      this.getUserList()
    },
    // 通过审核
    async auditUserById(userId) {
      const modifyStatusQs = { id: userId, status: 2 }
      const { data: res } = await this.$http.post('user/modifyStatus', modifyStatusQs)
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败！')
      }
      this.getUserList()
      return this.$message.success('更新用户状态成功!')
    },
    // 更改是否接收短信
    async userMessageStatuChanged(user) {
      // console.log(user)
      const modifyMessageQs = { id: user.id, receive_status: 1 }
      if (user.eceive_status === 1) {
        modifyMessageQs.receive_status = 2
      }
      // console.log(modifyMessageQs)
      const { data: res } = await this.$http.post('user/modifyReceiveStatus', modifyMessageQs)
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败!只能更改本人账号。')
      }
      this.getUserList()
      return this.$message.success('更新用户状态成功!')
    },
    // 重新启用账号
    async reactivateUserById(userId) {
      const { data: res } = await this.$http.post('user/modifyStatus', { id: userId, status: 2 })
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败!')
      }
      this.getUserList()
      return this.$message.success('更新用户状态成功!')
    },
    // 监听Switch状态的改变
    async userStatuChanged(userInfo) {
      // console.log(userInfo)
      var qsUser = {}
      qsUser.id = userInfo.id
      if (userInfo.status === true) {
        qsUser.status = 2
      } else {
        qsUser.status = 9
      }
      const { data: res } = await this.$http.post('user/modifyStatus', qsUser)
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败!')
      }
      return this.$message.success('更新用户状态成功!')
    },
    // 监听添加用户的对话框关闭事件
    addDislogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击按钮,添加新用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起添加用户请求
        // console.log(this.addForm)
        const { data: res } = await this.$http.post('user/create', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('用户添加失败了~')
        }
        // 隐藏添加用户的对话框
        this.addDialogVisible = false
        // 添加成后重新获取用户数据,不需要用户手动刷新
        this.getUserList()
        return this.$message.success('用户添加成功了~')
      })
    },
    // 展示编辑用于的对话框
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('users/' + id)
      if (res.meta.status !== 200) {
        return this.$message.error('查询用户数据失败~')
      }
      this.editForm = res.data
      // console.log(res)
      this.editDialogVisble = true
      return this.$message.success('查询用户数据成功~')
    },
    // 监听修改用户对话框的关闭事件
    aditClosed() {
      this.$refs.editFormRef.resetFields()
    },
    editUserInfo() {
      this.$refs.editFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) return
        // 发起修改用户信息的数据请求
        const { data: res } = await this.$http.put('users/' + this.editForm.id, {
          email: this.editForm.email,
          mobile: this.editForm.mobile
        })
        if (res.meta.status !== 200) {
          this.$message.error('更新用户信息失败!')
        }
        this.editDialogVisble = false
        this.getUserList()
        this.$message.success('更新用户信息成功!')
      })
    },
    // 根据id删除对应的用户信息
    async removeUserById(id) {
      // 询问用户是否删除用户
      const confirmRusult = await this.$confirm('此操作将删除该用户, 是否继续?', '永久删除该用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // console.log(confirmRusult)
      // 用户点击了删除,则返回字符串 confirm
      // 用户取消了删除,则返回字符串 cancel
      if (confirmRusult !== 'confirm') {
        return this.$message.info('已经取消了删除')
      }
      const deletIdQs = {
        id: id
      }
      this.$http.post('user/del', deletIdQs).then(res => {
        const { data: response } = res
        // console.log(response)
        if (response.code !== 200) {
          return this.$message.error('该用户删除失败')
        }
        this.$message.success('该用户已经删除')
        this.getUserList()
      })
    },
    // 展示分配角色的对话框
    async setRoles(userInfo) {
      this.userInfo = userInfo
      // 再展示对话框之前获取所有的角色列表
      const { data: res } = await this.$http.get('roles')
      if (res.meta.status !== 200) {
        return this.$message.error('获取角色列表失败!')
      }
      this.rolesList = res.data
      this.setRolesDialogVisible = true
    },
    // 点击按钮,分配角色
    async saveRolesInfo() {
      if (!this.selectRoleId) {
        return this.$message.error('请选择要分配的角色!')
      }
      const { data: res } = await this.$http.put(`users/${this.userInfo.id}/role`, {
        rid: this.selectRoleId
      })
      if (res.meta.status !== 200) {
        return this.$message.error('更新角色失败!')
      }
      this.$message.success('更新角色成功!')
      this.getUserList()
      this.setRolesDialogVisible = false
    },
    // 分配角色对话框关闭
    setRolesDialogClosed() {
      this.selectRoleId = ''
      this.userInfo = ''
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
