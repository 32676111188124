<template>
  <div class="order">
    <Breadcrumb name1="年度评价" name2="评分统计"/>
    <el-card>
      <el-row v-if = "scoreList.length > 1" type="flex" justify="center" :gutter="20">
          <el-col :span="4" class="text-center">
            <el-card shadow="always">
              <div class="number-big">{{ smsSendCount.total }}</div>
              全部短信用量
            </el-card>
          </el-col>
          <el-col :span="4" class="text-center">
            <el-card shadow="always">
              <div class="number-big">{{ smsSendCount.month }}</div>
              本月短信用量
            </el-card>
          </el-col>
          <el-col :span="4" class="text-center">
            <el-card shadow="always">
              <div class="number-big">{{ smsSendCount.day }}</div>
              今日短信用量
            </el-card>
          </el-col>
      </el-row>
      <!--      表格单个-->
      <el-row v-if = "scoreList.length == 1" type="flex" justify="center">
          <el-col :span="4" class="text-center">
            <el-progress type="circle" :percentage="Math.floor(scoreList[0].result.progress * 100)" :format="format"></el-progress>
           <br/>单位名称
            {{scoreList[0].name}}
            <br/>
            年度得分
            {{ Math.floor(scoreList[0].result.progress * 100)}}
          </el-col>
      </el-row>
      <!--      分页区-->
      <el-row type="flex" justify="center" v-else>
        <el-col :span='12'>
          <el-table
          :data="scoreList"
          style="width: 100%">
          <el-table-column
          label="ID"
          prop="id"
          width="50">
        </el-table-column>
          <el-table-column
            prop="name"
            label="单位名称">
          </el-table-column>
          <el-table-column
            prop="result.total_count"
            label="任务数量"
            sortable
            width="110">
          </el-table-column>
          <el-table-column
            prop="result.completed_count"
            label="已完成"
            sortable
            width="110">
          </el-table-column>
          <el-table-column
            prop="result.uncompleted_count"
            label="未完成"
            sortable
            width="110">
          </el-table-column>
          <el-table-column
            prop="result.months"
            label="超期"
            width="110">
              <template slot-scope="scope">
                {{getTotalUncompletedCount(scope.row.result.months)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="result.progress"
            sortable
            width="200"
            label="年度得分">
            <template slot-scope="scope">
              <el-progress :percentage="Math.floor(scope.row.result.progress * 100)" :format="format" :stroke-width="12">
              </el-progress>
            </template>
          </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Score',
  components: {
    Breadcrumb
  },
  data() {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 7
      },
      scoreList: [],
      smsSendCount: {
        day: 0,
        month: 0,
        total: 0
      }
    }
  },
  created() {
    this.getScoreList()
    this.getSmsData()
  },
  methods: {
    // 格式显示进度条
    format(percentage) {
      return percentage === 100 ? '100分' : `${percentage}分`
    },
    // 获取数据
    async getScoreList() {
      const { data: res } = await this.$http.post('project/task/statistics')
      if (res.code !== 200) {
        return this.$message.error('数据获取失败请重新登陆')
      }
      this.scoreList = res.data.filter(item => item.id !== 1 && item.id !== 46)
      // console.log(res)
    },
    // 获取数据短信统计
    async getSmsData() {
      const { data: res } = await this.$http.get('sms/count')
      if (res.code !== 200) {
        return this.$message.error('数据获取失败请重新登陆')
      }
      // this.scoreList = res.data
      console.log(res)
      this.smsSendCount = res.data
    },
    // 计算到哪月之前未完成的统计
    getTotalUncompletedCount(data) {
      const now = new Date()
      const currentMonth = now.getMonth() + 1
      let totalUncompleted = 0
      for (let month = 1; month <= currentMonth; month++) {
        if (data[month].uncompleted_count !== undefined) {
          totalUncompleted += data[month].uncompleted_count
        }
      }
      return totalUncompleted - data[currentMonth].uncompleted_count
    }
  }
}
</script>

<style scoped>
.el-table {
  margin: 15px 0 5px;
}
.number-big{ font-size: 34px; color:#409EFF}
.text-center{ text-align:center;}
</style>
